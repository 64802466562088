
const isMobile = true
export default {
  name: 'OrganismLocation',
  props: {
    locationData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showSidebar: !isMobile,
      queriedAddress: '',
      searchResult: {
        success: true,
        text: '',
        addressText: '',
        directionsResponse: {},
        queryResults: null
      },
      suggestions: [],
      icon: {
        name: 'magnifying-glass',
        color: '#000',
        size: 14,
        onClick: () => {}
      },
      isDropdownOpen: false
    }
  },
  computed: {
    searchDisabled() {
      return !this.queriedAddress
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    hasSearchResult() {
      return this.searchResult.text !== '' && this.searchResult.queryResults
    },
    hasSearchError() {
      return this.searchResult.text !== '' && !this.searchResult.success
    }
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    showDirectionsForMarkerId(id) {
      this.$refs.map.triggerMarkerById(id)
    },
    handleSearch(idx) {
      this.queriedAddress = this.suggestions[idx].value
      this.searchResult = this.$refs.map.distanceFrom(this.queriedAddress)
      this.$refs.searchInput.resetInput()
      this.suggestions = []
    },
    focusSearch() {
      this.$refs.searchInput.focus()
    },
    async handleSelection(text) {
      this.queriedAddress = text
      if (text.length === 0) {
        this.isDropdownOpen = false
        this.icon.name = 'magnifying-glass'
        this.suggestions = []
      }
      if (text.length > 0) {
        this.icon.onClick = this.handleIconClick
        this.isDropdownOpen = true
        this.icon.name = 'xmark'
      }
      const self = this
      this.suggestions = await new Promise((resolve, reject) => {
        self.$refs.map.autocompleteService.getPlacePredictions(
          {
            input: text
          },
          (predictions, status) => {
            if (status !== self.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
              resolve([{ text: 'No result found.', value: 'No result found.' }])
            }
            resolve(predictions?.map((p) => ({ text: p.description, value: p.description })))
          }
        )
      })
    },
    handleIconClick(iconName) {
      if (iconName === 'xmark') {
        this.queriedAddress = ''
        this.isDropdownOpen = false
        this.icon.name = 'magnifying-glass'
        this.icon.onClick = () => {}
        this.suggestions = []
      }
    }
  }
}
